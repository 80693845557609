/* eslint-disable import/prefer-default-export */
export const EMPLOYEE_COL_IMPORT = {
  type: 4,
  gender: 5,
  firstName: 3,
  lastName: 2,
  username: 6,
  emailAddress: 7,
  phoneNumber: 8,
  bookerType: 9,
  no: 1,
}
